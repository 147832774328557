@font-face {
  font-family: SourceSansProThin;
  src: url('../fonts/source_sans_pro_thin.ttf');
}

@font-face {
  font-family: SourceSansProLight;
  src: url('../fonts/source_sans_pro_light.ttf');
}

@font-face {
  font-family: SourceSansPro;
  src: url('../fonts/source_sans_pro_regular.ttf');
}

@font-face {
  font-family: SourceSansProBold;
  src: url('../fonts/source_sans_pro_bold.ttf');
}
