@import '../../../assets/scss/dimensions';
@import '../../../assets/scss/numbers';
@import '../../../assets/scss/colors';

@mixin trickplay-icon-osd {
  width: 105px;
  height: 40px;
}

.trick-play-osd-container {
  position: absolute;
  top: -248px;
  left: calc(50vw - 166px);
  width: 297px;
  height: 126px;
  background-color: $spinner-container-background-color;
  overflow: hidden;

  .trick-play-osd-time-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .trick-play-icon {
      padding-top: 16px;
    }

    .trick-play-time {
      padding-top: 16px;
      height: 38px;
      font-family: SourceSansPro, sans-serif;
      font-size: $spinner-message-font-size;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $primary-text-color;
    }

  }

}

.osd-ff1-icon {
  @include trickplay-icon-osd;
  content: url('../../../assets/images/trickplay/ic-trickplay_osd_fast_forward_1.png');
}

.osd-ff2-icon {
  @include trickplay-icon-osd;
  content: url('../../../assets/images/trickplay/ic-trickplay_osd_fast_forward_2.png');
}

.osd-ff3-icon {
  @include trickplay-icon-osd;
  content: url('../../../assets/images/trickplay/ic-trickplay_osd_fast_forward_3.png');
}

.osd-rw1-icon {
  @include trickplay-icon-osd;
  content: url('../../../assets/images/trickplay/ic-trickplay_osd_rewind_1.png');
}

.osd-rw2-icon {
  @include trickplay-icon-osd;
  content: url('../../../assets/images/trickplay/ic-trickplay_osd_rewind_2.png');
}

.osd-rw3-icon {
  @include trickplay-icon-osd;
  content: url('../../../assets/images/trickplay/ic-trickplay_osd_rewind_3.png');
}
